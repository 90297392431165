<template>
  <v-container class="bg-color-theme px-1">
    <validation-observer :ref="'observer'">
      <v-form
        ref="form"
        @submit.prevent="submit"
        lazy-validation
        autocomplete="off"
      >
        <v-card
          elevation="2"
          class="py-6 px-4 mb-5"
          v-for="(card, index) in mailCards"
          :key="index"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <validation-provider v-slot="{ errors }" rules="" name="">
                <v-label>
                  <span class="font-13px text-a9">
                    表示名
                  </span>
                </v-label>
                <v-text-field
                  class="font-14px"
                  placeholder="氏名"
                  v-model="card.name"
                  :error-messages="errors"
                  autocomplete="chrome-off"
                  dense
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                rules="required|email"
                name="email"
              >
                <v-label>
                  <span class="font-13px text-a9">
                    メールアドレス
                  </span>
                </v-label>
                <v-text-field
                  class="font-14px"
                  placeholder="mail@redish.com"
                  v-model="card.email_address"
                  :error-messages="errors"
                  autocomplete="chrome-off"
                  dense
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card>
        <div class="d-flex flex-column align-center">
          <v-btn
            color="primary"
            elevation="0"
            fab
            height="24"
            width="24"
            @click="addMailCards"
            class="mb-5"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn color="primary" width="315px" type="submit" elevation="3"
            >更新する</v-btn
          >
        </div>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
export default {
  name: "AddMail",
  data() {
    return {
      errors: "",
      mail: "naninuneno@redish.com",
      name: "氏名",
      // adjust this according to api if needed and the corresponding method "addMailCards"
      mailCards: [
        {
          name: "",
          email_address: ""
        }
      ]
    };
  },

  methods: {
    addMailCards() {
      this.mailCards.push({
        name: "",
        email_address: ""
      });
    },

    submit() {
        this.$refs.observer.validate().then(success => {
          if (!success) {
            return
          }

          let params = {};
          let shop_id=this.$store.getters.getFirstShopObject.id;
          params.mailing_list=this.mailCards;

          this.$store.dispatch('MAILINGLIST_REGISTER', {params,shop_id}).then(
            response => {
              if (response.data.data.status == 'success') {
                
                this.$router.push({ path: "/mailing-list" });
                this.$store.dispatch("SHOW_ALERT", {
                  text: "メーリングリストを追加しました。",
                  successStatus: "info"
                });
              }
            },
            error => {
              if (error?.data?.errors)
              this.$refs.observer.setErrors(error?.data?.errors);
            }
          )
        })
    }
  }
};
</script>
